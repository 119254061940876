<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>商品管理</template>
      <template v-slot:secondMenu>评论配置</template>
    </breadcrumb-nav>
    <el-card>
    <el-button
      type="primary"
      icon="el-icon-plus"
      style="margin: 10px 0px"
      @click="showDialog"
    >添加
    </el-button>
    <el-table style="width: 100%" border :data="list">
      <el-table-column type="index" label="序号" width="80px" align="center">
      </el-table-column>
      <el-table-column prop="comment" label="评论内容">
      </el-table-column>
      <el-table-column prop="prop" label="操作" width="200px">
        <template slot-scope="{row}">
          <el-button
            type="warning"
            icon="el-icon-edit"
            size="mini"
            @click="updateGoodsComment(row)"
          >修改</el-button
          >
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            @click="delGoodsComment(row)"
          >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listQuery.page"
        :page-sizes="[5, 10]"
        :page-size="listQuery.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    <el-dialog
      :title="form.id ? '修改' : '添加'"
      :visible.sync="dialogFormVisible"
    >
      <el-form style="width: 80%" :model="form" :rules="rules" ref="form">
        <el-form-item label="评论内容" prop="comment">
          <el-input v-model="form.comment"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrUpdateGoodsComment"
        >确 定</el-button
        >
      </div>
    </el-dialog>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import {
  delGoodsCommentKnowledgeRequest,
  listGoodsCommentKnowledge,
  updateGoodsCommentKnowledgeRequest,
  addGoodsCommentKnowledgeRequest
} from '../../../network/goods'

export default {
  name: 'GoodsComment',
  components: {
    BreadcrumbNav
  },
  data () {
    return {
      listQuery: {
        page: 1,
        size: 10
      },
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      // 对话框显示与隐藏控制的属性
      dialogFormVisible: false,
      // 收集签到规则信息
      form: {
        comment: ''
      },
      // 表单验证规则
      rules: {
        comment: [
          { required: true, message: '请输入评论内容', trigger: 'blur' }
        ]
      }
    }
  },
  // 组件挂载完毕发请求
  mounted () {
    // 获取列表数据方法
    this.listGoodsComment()
  },
  methods: {
    listGoodsComment () {
      listGoodsCommentKnowledge(this.listQuery).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取规则列表失败', 'error')
        }
        this.list = result.data.list
        this.total = result.data.total
      })
    },
    updateGoodsComment (row) {
      this.dialogFormVisible = true
      this.form = { ...row }
    },
    delGoodsComment (row) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delGoodsCommentKnowledgeRequest(row.id).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('删除失败', 'error')
          }
          this.alertMessage('删除成功', 'success')
          this.listGoodsComment()
        })
      }).catch(() => {
        this.alertMessage('已取消删除', 'info')
      })
    },
    showDialog () {
      this.dialogFormVisible = true
      this.form = { comment: '' }
    },
    updateGoodsComment2Srv () {
      updateGoodsCommentKnowledgeRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改失败', 'error')
        }
        this.alertMessage('修改成功', 'success')
        this.listGoodsComment()
      })
    },
    addGoodsComment2Srv () {
      addGoodsCommentKnowledgeRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('添加失败', 'error')
        }
        this.alertMessage('添加成功', 'success')
        this.listGoodsComment()
      })
    },
    addOrUpdateGoodsComment () {
      this.$refs.form.validate(valid => {
        if (!valid) return
        this.dialogFormVisible = false
        if (this.form.id) {
          this.updateGoodsComment2Srv()
        } else {
          this.addGoodsComment2Srv()
        }
      })
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.listGoodsComment()
    },
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.listGoodsComment()
    }
  }
}
</script>

<style scoped>

</style>
